import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import logo from "./logoand45.png";
import exportImage from "./export.png";
// import ChihadeVideo from "./ChihadeVideo.mov";
import ChihadeVideo from "./ChihadeVideo.mp4";
import Contact from './Contact.js';
import OurMission from './OurMission.js';
import History from './History.js';
import Layout from './Layout.js';
import Team from './Team.js'

import './App.css';
import './Contact.css';
import './OurMission.css';
import './History.css';
import './Team.css';

function Home() {
  const [isPlaying, setIsPlaying] = useState(true);
  const [videoError, setVideoError] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = (e) => {
      // Only play the video if the click is within the video or the control button
      if (
        videoRef.current &&
        (videoRef.current.contains(e.target) || e.target.closest('.video-control'))
      ) {
        if (!isPlaying) {
          videoRef.current.play().catch(e => {
            console.error("Video playback error:", e);
            setVideoError(e.message);
          });
          setIsPlaying(true);
        }
        document.removeEventListener('click', playVideo); // Remove listener after play
      }
    };

    document.addEventListener('click', playVideo);

    return () => {
      document.removeEventListener('click', playVideo);
    };
  }, [isPlaying]);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(e => {
          console.error("Video playback error:", e);
          setVideoError(e.message);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>



      {/* Content Sections */}
      <section className="content-section">
        <div className="left-content">

        </div>
      </section>

      <div></div>
      <div className="video-banner" style={{ position: 'relative', width: '100%', height: '490px', marginTop: '-60px' }}>
  <video
    ref={videoRef}
    autoPlay
    muted
    loop
    style={{ width: '100%', height: '150%', objectFit: 'cover' }}
    onError={(e) => {
      console.error("Video error:", e);
      setVideoError(e.message);
    }}
  >
    <source src={ChihadeVideo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  {/* Hazel-colored background overlay */}
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '130%',
      backgroundColor: 'rgba(255, 255, 255, 0.4)', // Hazel color with transparency
      zIndex: 1, // Ensures the overlay is above the video but below other content
    }}
  ></div>

<div className="video-overlay" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '130%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 2 }}>
<h2 
      style={{
        color: '#333',
        fontSize: '3.5rem',
        fontFamily: '"Helvetica Neue", Arial, sans-serif',
        fontWeight: 600,
        textAlign: 'center',
        maxWidth: '80%',
        margin: '0 auto',
        padding: '30px 20px',
        borderBottom: '5px solid #DBB829',
        lineHeight: 1.4,
      }}
    >
      <span style={{ fontWeight: 800, color: '#DBB829' }}>Exporting & Consolidating  </span>
      
      U.S. Food Products Since 1979
    </h2>


   

  <button
    className="video-control bounce"
    onClick={togglePlay}
    style={{
      position: 'absolute', // Absolute positioning within the overlay
      bottom: '90px', // Place the button near the bottom edge
      left: '10px', // Place the button near the left edge
      backgroundColor: '#DBB829', // Yellow background color
      border: 'none',
      borderRadius: '50%',
      width: '60px',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'transform 0.2s', // Smooth transition for hover effect
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.transform = 'scale(1.1)'; // Scale up on hover
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.transform = 'scale(1)'; // Scale back on leave
    }}
  >
    {isPlaying ? (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
        <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
        <path d="M8 5v14l11-7z" />
      </svg>
    )}
  </button>
</div>
</div>




      <section className="content-section">
        <div className="left-content">
          <img src={exportImage} alt="ExoExportprt" />
        </div>
        <div className="right-content">
          <p className="section-number">1/3</p>
          <h2 className="section-title">EXPORT SOLUTIONS</h2>
          <p className="section-text">
            We offer comprehensive vendor solutions that streamline the procurement
            process and provide cost-effective strategies for our clients' supply
            chain needs.
          </p>
        </div>
      </section>



      {/* Other Sections */}
      <section className="content-section">
        <div className="right-content">
          <p className="section-number">2/3</p>
          <h2 className="section-title">WAREHOUSE</h2>
          <p className="section-text">
            Our state-of-the-art warehouse solutions allow for the efficient storage
            and management of a variety of products, ensuring timely distribution
            to meet the demands of a global market.
          </p>
        </div>
        <div className="left-content">
          <img src={exportImage} alt="Warehouse" />
        </div>
      </section>

      <section className="content-section">
        <div className="left-content">
          <img src={exportImage} alt="Vendor" />
        </div>
        <div className="right-content">
          <p className="section-number">3/3</p>
          <h2 className="section-title">VENDOR SOLUTIONS</h2>
          <p className="section-text">
            We offer comprehensive vendor solutions that streamline the procurement
            process and provide cost-effective strategies for our clients' supply
            chain needs.
          </p>
        </div>
      </section>
    </>
  );
}


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="our-mission" element={<OurMission />} />
        <Route path="history" element={<History />} />
        <Route path="team" element={<Team />} />
      </Route>
    </Routes>
  );
}

export default App;