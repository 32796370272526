import React from 'react';
import './Team.css';
import pfp from './pfp.jpg';
import TeamBanner from './OurTeam.jpg';

function Team() {
  const teamMembers = [
    { name: 'Tawfig Chihade', title: 'Founder' },
    { name: 'John Chihade', title: 'President' },
    { name: 'Derek Clever', title: 'General Manager' },
    { name: 'Asma Bedoui', title: 'Sales Manager, UAE & Oman' },
    { name: 'Carlos Guzman', title: 'Sales Manager, LATAM & Caribbean' },
    { name: 'Dominic Frank', title: 'Protein & Foodservice Specialist' },
    { name: 'Hector De La Garza', title: 'Warehouse Manager' },
    { name: 'Marlon Beltran', title: 'Purchasing Supervisor' },
    { name: 'Cherry Lie Sta Cruz', title: 'Logistics Supervisor' },
    { name: 'Ronald Pastrana', title: 'Sales Manager, KSA, Qatar, Bahrain & Kuwait' },
    { name: 'Chantha Pong', title: 'International Sales Coordinator' },
    { name: 'Tiffany Puaux Feugère', title: 'National Accounts Coordinator' },
    { name: 'Isela Sanchez', title: 'Export Label Manager' },
    { name: 'Christian Winters', title: 'Operations & Export Director' },
    { name: 'Kelly Wolf', title: 'HR & Office Manager' },
    { name: 'Matt Wolf', title: 'Purchasing & Customer Service Manager' }
  ];

  // Sort remaining team members alphabetically after Derek
  const otherMembers = teamMembers.slice(3).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="team">
      <div className="team-banner">
        <img src={TeamBanner} alt="Our Team" />
        <h2>Welcome to Chihade International</h2>
        <p>
          Our employees are the backbone of our reputation for excellence. We treat our team like family, resulting in:
        </p>

      </div>
      <div className="team-intro">
        
      </div>

      <div className="team-members">
        {/* Chihade family members */}
        <div className="level-members">
          <div className="team-member">
            <img src={pfp} alt={`${teamMembers[0].name} profile`} className="team-member-pic" />
            <div className="team-member-info">
              <h3>{teamMembers[0].name}</h3>
              <p>{teamMembers[0].title}</p>
            </div>
          </div>
          <div className="team-member">
            <img src={pfp} alt={`${teamMembers[1].name} profile`} className="team-member-pic" />
            <div className="team-member-info">
              <h3>{teamMembers[1].name}</h3>
              <p>{teamMembers[1].title}</p>
            </div>
          </div>
        </div>

        {/* Derek on a separate row */}
        <div className="level-members">
          <div className="team-member">
            <img src={pfp} alt={`${teamMembers[2].name} profile`} className="team-member-pic" />
            <div className="team-member-info">
              <h3>{teamMembers[2].name}</h3>
              <p>{teamMembers[2].title}</p>
            </div>
          </div>
        </div>

        {/* Other members in alphabetical order */}
        <div className="level-members">
          {otherMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img src={pfp} alt={`${member.name} profile`} className="team-member-pic" />
              <div className="team-member-info">
                <h3>{member.name}</h3>
                <p>{member.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
