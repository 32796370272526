import React from 'react';
import './History.css';

function History() {
  return (
    <div className="history">
      <div className="history-banner">
        <h1>Why Chihade International, Inc.?</h1>
      </div>
      <div className="history-content">
        <p>
          Founded in 1979 by Tawfig Chihade, Chihade International, Inc. has grown from a retail business to a leader in supply chain management for Franchise, Consolidation, Protein, and Foodservice markets. 
        </p>
        <p>
          With over 45 years of experience, we excel in all aspects of exporting food products—from purchasing to compliance with country-specific regulations. Our diverse team expertly navigates international markets, ensuring we meet the unique needs of each partner country.
        </p>
        <p>
          Why Choose Us?
        </p>
        <ul>
          <li>Family-owned, focused on long-term partnerships</li>
          <li>45+ years of expertise in the food export industry</li>
          <li>Fast, reliable problem-solving</li>
          <li>Flexible order financing</li>
          <li>Experts in beef and other food products</li>
          <li>Trusted by over 300 vendors</li>
          <li>Authority on food export logistics</li>
          <li>Reputation for excellence and integrity</li>
        </ul>
      </div>
    </div>
  );
}

export default History;
