import React from 'react';
import './OurMission.css';

function OurMission() {
  return (
    <div className="our-mission">
      <div className="mission-banner">
        <h1>Our Mission</h1>
      </div>
      <div className="mission-content">
        <div className="mission-card">
          <p>
            At Chihade International, Inc., our mission is to provide outstanding international supply chain management. With over 45 years of experience, we offer expert product knowledge, cultural awareness, and efficient logistics to ensure seamless food exports worldwide.
          </p>
          <p>
            Our long-term relationships, built on trust, include US franchises and specialty producers, many of whom have partnered with us since 1979.
          </p>
          <p>
            Our success comes from dedicated employees who offer:
          </p>
          <ul>
            <li>Expertise and attention to detail</li>
            <li>Years of experience</li>
            <li>Low turnover</li>
            <li>A strong sense of urgency and precision</li>
          </ul>
          <p>
            This ensures quality, value, and lasting partnerships.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurMission;