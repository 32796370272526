import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import logo from './logoand45.png';
import './Layout.css'; // We'll add this for additional styling

function Layout() {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`App ${scrolled ? 'scrolled' : ''}`}>
      <header className="App-header">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Logo and 45 years" className="App-logo" />
          </Link>
        </div>
        <div className="vertical-line"></div>
        <div className="header-text">
          <h1>WE SPEAK FOOD IN EVERY LANGUAGE</h1>
         
          <Link to="/contact" className="contact-us">
            CONTACT US
          </Link>
        </div>
        {/* Hamburger Icon */}
        <div className="hamburger-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>

      {/* Navigation Menu */}
      <nav className={`golden-nav ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li className="dropdown">
            <Link to="/about" onClick={() => setIsMenuOpen(false)}>About</Link>
            <div className="dropdown-content">
              <Link to="/our-mission" onClick={() => setIsMenuOpen(false)}>Our Mission</Link>
              <Link to="/history" onClick={() => setIsMenuOpen(false)}>History</Link>
              <Link to="/team" onClick={() => setIsMenuOpen(false)}>Our Team</Link>
            </div>
          </li>
          <li><Link to="/export" onClick={() => setIsMenuOpen(false)}>Export Solutions</Link></li>
          <li><Link to="/warehouse" onClick={() => setIsMenuOpen(false)}>Warehouse Solutions</Link></li>
          <li><Link to="/vendor" onClick={() => setIsMenuOpen(false)}>Vendor Solutions</Link></li>
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}

export default Layout;
