import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import logo from "./logoand45.png";
import contactBanner from './ContactUs.png';
import email from './email.png';
import phone from './phone.png';
import address from './address.png';

function ContactUs() {
  const [scrolled, setScrolled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
      .then((result) => {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      }, (error) => {
        alert('Failed to send message, please try again.');
      });
  };

  return (
    <div className={`App ${scrolled ? 'scrolled' : ''}`}>
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} alt="Logo and 45 years" className="App-logo" />
        </div>
        <div className="vertical-line"></div>
        <div className="header-text">
          <h1>WE SPEAK FOOD IN EVERY LANGUAGE</h1>
          <p className="subheader">
          Exporting & Consolidating U.S Food Products Since 1979
          </p>
        </div>
      </header>

      <div className="banner-image">
        <img src={contactBanner} alt="Contact Us Banner" />
      </div>

      <div className="contact-content">
        <h1 className="contact-title">Contact Us</h1>
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p><img src={address} alt="Address" /> Chihade International, Inc. 1025 Raco Court Suite D, Lawrenceville, GA 30046 USA</p>
          <p><img src={phone} alt="Phone" /> +1-404-292-5033</p>
          <p><img src={email} alt="Email" /> info@chihadeintl.com</p>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
